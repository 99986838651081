var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.canShowSection()
    ? _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c(
            "b-card-header",
            {
              directives: [
                {
                  name: "b-toggle",
                  rawName: "v-b-toggle.verify-order-accordion-1",
                  modifiers: { "verify-order-accordion-1": true },
                },
              ],
              staticClass: "accordion-header",
              class: { disabled: _vm.sectionIsDisabled("company_name_lookup") },
              attrs: { "header-tag": "header", role: "tab" },
              on: {
                "!click": function ($event) {
                  $event.stopPropagation()
                  return _vm.$emit("set-as-current")
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-content-between align-items-center",
                },
                [
                  _c("div", { staticClass: "header-left" }, [
                    _c("h4", [_vm._v("\n          Company Name\n        ")]),
                    _c(
                      "h5",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.sectionIsComplete("company_name_lookup"),
                            expression:
                              "sectionIsComplete('company_name_lookup')",
                          },
                        ],
                      },
                      [
                        _c("check-circle", {
                          staticClass: "check-circle",
                          attrs: { width: "20", height: "20" },
                        }),
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.companyName) +
                            "\n        "
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("feather-icon", { attrs: { type: "chevron-right" } }),
                ],
                1
              ),
            ]
          ),
          _c(
            "b-collapse",
            {
              ref: "verify-order-accordion-1",
              attrs: {
                id: "verify-order-accordion-1",
                accordion: "verify-order-accordion",
                role: "tabpanel",
                visible: _vm.currentSection === "company_name_lookup",
              },
            },
            [
              _c("b-card-body", [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-column justify-content-center align-items-center py-4",
                  },
                  [
                    _vm.companyNameSearching
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-column justify-content-center align-items-start py-4 searching-container",
                          },
                          [
                            _c("p", { staticClass: "searching-availability" }, [
                              _vm._v(
                                "\n            Please wait while we check availability\n            "
                              ),
                              _c("span", { staticClass: "searching-dot" }, [
                                _vm._v("."),
                              ]),
                              _c("span", { staticClass: "searching-dot" }, [
                                _vm._v("."),
                              ]),
                              _c("span", { staticClass: "searching-dot" }, [
                                _vm._v("."),
                              ]),
                            ]),
                            _c("p", { staticClass: "searching-wait" }, [
                              _vm._v("This may take a few minutes."),
                            ]),
                          ]
                        )
                      : _c("div", { staticClass: "availability-container" }, [
                          _c("div", { staticClass: "grid-container" }, [
                            _c(
                              "div",
                              { staticClass: "grid-column-1" },
                              [
                                _vm.companyNameAvailable
                                  ? _c(
                                      "div",
                                      { staticClass: "icon-container" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "available-icon-area",
                                          },
                                          [
                                            _c("check-circle", {
                                              staticClass: "check-circle",
                                              attrs: {
                                                width: "74px",
                                                height: "74px",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  : _c("not-available-x", {
                                      attrs: { width: "24", height: "24" },
                                    }),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "grid-column-2" }, [
                              _vm.companyNameAvailable
                                ? _c("div", { staticClass: "available" }, [
                                    _c(
                                      "p",
                                      { staticClass: "state-confirmation" },
                                      [
                                        _c("strong", [
                                          _vm._v(
                                            "\n                    Congrats!\n                  "
                                          ),
                                        ]),
                                        _vm._v(
                                          "\n                  The business name\n                "
                                        ),
                                      ]
                                    ),
                                    _c("p", { staticClass: "company-name" }, [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(_vm.currentCompanyName) +
                                          "\n                  "
                                      ),
                                      _c("br"),
                                      _c(
                                        "span",
                                        { staticClass: "available-confirm" },
                                        [
                                          _vm._v(
                                            "\n                    is available.\n                  "
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ])
                                : _c("div", [
                                    _c(
                                      "p",
                                      { staticClass: "not-available-confirm" },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "company-name" },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(_vm.currentCompanyName) +
                                                "\n                  "
                                            ),
                                          ]
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(_vm.unavailableMessage) +
                                            "\n                "
                                        ),
                                      ]
                                    ),
                                    _c("p", { staticClass: "input-header" }, [
                                      _vm._v(
                                        "\n                  Please search for another name.\n                "
                                      ),
                                    ]),
                                  ]),
                              _vm.showSearch
                                ? _c(
                                    "div",
                                    { staticClass: "input-container" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.newCompanyName,
                                            expression: "newCompanyName",
                                          },
                                        ],
                                        attrs: {
                                          type: "text",
                                          placeholder: "Company Name",
                                          required: "",
                                        },
                                        domProps: { value: _vm.newCompanyName },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.newCompanyName =
                                              $event.target.value
                                          },
                                        },
                                      }),
                                      _c("b-form-select", {
                                        staticClass: "float-left",
                                        attrs: {
                                          options: _vm.entityTypeSuffixes,
                                          required: "",
                                        },
                                        model: {
                                          value: _vm.selectedEntitySuffix,
                                          callback: function ($$v) {
                                            _vm.selectedEntitySuffix = $$v
                                          },
                                          expression: "selectedEntitySuffix",
                                        },
                                      }),
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "continue-btn btn btn-outline-primary",
                                          attrs: {
                                            "aria-label": "search button",
                                            disabled: _vm.disableSearch,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.performCompanyNameSearch()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  Search\n                "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]),
                          ]),
                        ]),
                    _vm.showSearchMore
                      ? _c(
                          "div",
                          {
                            staticClass: "research-button d-flex justify-start",
                          },
                          [
                            _c(
                              "b-button",
                              {
                                attrs: {
                                  variant: "outline-primary",
                                  "aria-label": "new search button",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.openSearchInput()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n            Search For Another Name\n          "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
                _vm.showSuccessfulSearches
                  ? _c("div", { staticClass: "successful-matches" }, [
                      _c("div", { staticClass: "successful-matches-text" }, [
                        _vm._v("\n          Successful Matches:\n        "),
                      ]),
                      _c(
                        "div",
                        { staticClass: "successful-matches-buttons" },
                        _vm._l(_vm.successfulNames, function (name) {
                          return _c(
                            "b-button",
                            {
                              key: name,
                              staticClass: "successful-match-button py-0",
                              attrs: {
                                variant: "outline-primary",
                                "aria-label": "successful match button",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.performCompanyNameSearch(name)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n            " + _vm._s(name) + "\n          "
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "availability-note" }, [
                  _vm._v(
                    "\n        Disclaimer: Final determination of name availability is at the discretion of the state at the time of filing.\n      "
                  ),
                ]),
              ]),
              _c(
                "b-card-footer",
                {
                  staticClass: "accordion-footer",
                  attrs: { "footer-tag": "footer", role: "tab" },
                },
                [
                  !_vm.updatingCompanyName
                    ? _c(
                        "b-button",
                        {
                          staticClass:
                            "upsell-button standard-verify-button-size button-upgrade",
                          attrs: {
                            variant: "primary",
                            "aria-label": "continue button",
                            disabled:
                              !_vm.companyNameAvailable ||
                              _vm.companyNameSearching,
                          },
                          on: { click: _vm.addCompanyNameAndProceed },
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm.firstSearch
                                  ? "Continue"
                                  : "Select This Name"
                              ) +
                              "\n      "
                          ),
                        ]
                      )
                    : _c("ct-centered-spinner"),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }