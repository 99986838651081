<template>
  <b-card
    v-if="canShowSection()"
    no-body
  >
    <b-card-header
      v-b-toggle.verify-order-accordion-1
      :class="{ disabled: sectionIsDisabled('company_name_lookup') }"
      header-tag="header"
      class="accordion-header"
      role="tab"
      @click.stop.capture="$emit('set-as-current')"
    >
      <div class="d-flex justify-content-between align-items-center">
        <div class="header-left">
          <h4>
            Company Name
          </h4>
          <h5 v-show="sectionIsComplete('company_name_lookup')">
            <check-circle class="check-circle" width="20" height="20" />
            {{ companyName }}
          </h5>
        </div>
        <feather-icon type="chevron-right" />
      </div>
    </b-card-header>
    <b-collapse
      id="verify-order-accordion-1"
      ref="verify-order-accordion-1"
      accordion="verify-order-accordion"
      role="tabpanel"
      :visible="currentSection === 'company_name_lookup'"
    >
      <b-card-body>
        <div
          class="d-flex flex-column justify-content-center align-items-center py-4"
        >
          <div
            v-if="companyNameSearching"
            class="d-flex flex-column justify-content-center align-items-start py-4 searching-container"
          >
            <p class="searching-availability">
              Please wait while we check availability
              <span class="searching-dot">.</span>
              <span class="searching-dot">.</span>
              <span class="searching-dot">.</span>
            </p>
            <p class="searching-wait">This may take a few minutes.</p>
          </div>
          <div v-else class="availability-container">
            <div class="grid-container">
              <div class="grid-column-1">
                <div v-if="companyNameAvailable" class="icon-container">
                  <div class="available-icon-area">
                    <check-circle class="check-circle" width="74px" height="74px" />
                  </div>
                </div>
                <not-available-x v-else width="24" height="24" />
              </div>
              <div class="grid-column-2">
                <div v-if="companyNameAvailable" class="available">
                  <p class="state-confirmation">
                    <strong>
                      Congrats!
                    </strong>
                    The business name
                  </p>
                  <p class="company-name">
                    {{ currentCompanyName }}
                    <br>
                    <span class="available-confirm">
                      is available.
                    </span>
                  </p>
                </div>
                <div v-else>
                  <p class="not-available-confirm">
                    <span class="company-name">
                      {{ currentCompanyName }}
                    </span>
                    <br>
                    {{ unavailableMessage }}
                  </p>
                  <p class="input-header">
                    Please search for another name.
                  </p>
                </div>
                <div v-if="showSearch" class="input-container">
                  <input
                    v-model="newCompanyName"
                    type="text"
                    placeholder="Company Name"
                    required
                  >
                  <b-form-select
                    v-model="selectedEntitySuffix"
                    class="float-left"
                    :options="entityTypeSuffixes"
                    required
                  />
                  <button
                    class="continue-btn btn btn-outline-primary"
                    aria-label="search button"
                    :disabled="disableSearch"
                    @click="performCompanyNameSearch()"
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div v-if="showSearchMore" class="research-button d-flex justify-start">
            <b-button
              variant="outline-primary"
              aria-label="new search button"
              @click="openSearchInput()"
            >
              Search For Another Name
            </b-button>
          </div>
        </div>

        <div v-if="showSuccessfulSearches" class="successful-matches">
          <div class="successful-matches-text">
            Successful Matches:
          </div>

          <div class="successful-matches-buttons">
            <b-button
              v-for="name in successfulNames"
              :key="name"
              class="successful-match-button py-0"
              variant="outline-primary"
              aria-label="successful match button"
              @click="performCompanyNameSearch(name)"
            >
              {{ name }}
            </b-button>
          </div>
        </div>

        <div class="availability-note">
          Disclaimer: Final determination of name availability is at the discretion of the state at the time of filing.
        </div>
      </b-card-body>

      <b-card-footer
        footer-tag="footer"
        class="accordion-footer"
        role="tab"
      >
        <b-button
          v-if="!updatingCompanyName"
          class="upsell-button standard-verify-button-size button-upgrade"
          variant="primary"
          aria-label="continue button"
          :disabled="!companyNameAvailable || companyNameSearching"
          @click="addCompanyNameAndProceed"
        >
          {{ firstSearch ? 'Continue' : 'Select This Name' }}
        </b-button>
        <ct-centered-spinner v-else />
      </b-card-footer>
    </b-collapse>
  </b-card>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import { makeToastMixin } from '@/mixins/makeToastMixin'
import { logClientInteraction } from '@/common/modules/clientInteractionLog'

export default {
  name: 'CompanyNameLookupSection',
  components: {
    CheckCircle:        () => import('@images/ui/check-circle.svg'),
    FeatherIcon:        () => import('@/components/shared/FeatherIcon'),
    NotAvailableX:      () => import('@images/ui/not-available.svg'),
    CtCenteredSpinner:  () => import('@/components/shared/CtCenteredSpinner'),
  },
  mixins: [makeToastMixin],
  data() {
    return {
      loaded: false,
      newCompanyName: '',
      currentCompanyName: '',
      selectedEntitySuffix: null,
      companyNameSearching: false,
      companyNameAvailable: false,
      updatingCompanyName: false,
      runNewSearch: false,
      firstSearch: true,
      unavailableMessage: 'Name unavailable.',
      successfulNames: [],
    }
  },
  computed: {
    ...mapGetters('verifyOrder', [
      'currentSection',
      'sectionIsComplete',
      'sectionIsDisabled',
      'showSection',
      'company',
    ]),
    ...mapGetters('entityTypes', [
      'entityTypeSuffixes',
    ]),
    ...mapGetters('stagelineCreateCompany', [
      'fullCompanyName',
    ]),
    ...mapGetters('verifyOrder', [
      'verifyOrderInteraction',
    ]),
    companyName() {
      return this.company.name
    },
    entityTypeId() {
      return this.company.entity_type_id
    },
    jurisdictionId() {
      return this.company.domestic_registration.jurisdiction_id
    },
    disableSearch() {
      return !this.newCompanyName || this.selectedEntitySuffix == null
    },
    showSearch() {
      return !this.companyNameSearching && (!this.companyNameAvailable || this.runNewSearch)
    },
    showSearchMore() {
      return !this.runNewSearch && !this.firstSearch && this.companyNameAvailable
    },
    showSuccessfulSearches() {
      return this.showSearch && this.successfulNames.length
    },
  },
  async mounted() {
    if (!this.canShowSection()) return
    this.loaded = true
    this.currentCompanyName = this.companyName
    await this.loadEntityTypeSuffixes()
    await this.performCompanyNameSearch()
  },
  methods: {
    ...mapActions('companies', [
      'updateCompanyNameSimple',
    ]),
    ...mapActions('entityTypes', [
      'getEntityTypeSuffixes',
    ]),
    ...mapActions('stagelineCreateCompany', [
      'searchCompanyName',
      'setCompanyNameAndSuffix',
    ]),
    ...mapActions('verifyOrder', [
      'logVerifyOrderInteraction',
    ]),
    canShowSection() {
      // Need Domestic Jurisdiction to do a company name lookup
      return this.showSection('company_name_lookup') &&
        this.company?.domestic_registration?.jurisdiction_id
    },
    async addCompanyNameAndProceed() {
      this.updatingCompanyName = true
      let companyUpdateSuccess = true

      if (this.currentCompanyName !== this.companyName) {
        const fullCompanyName = this.currentCompanyName
        companyUpdateSuccess = await this.updateCompanyNameSimple({
          companyId: this.company.id,
          name: fullCompanyName,
        })
      }

      if (companyUpdateSuccess) {
        await this.logVerifyOrderInteraction('company-name-lookup-section-completed')
        this.$emit('completed')
      } else {
        this.errorToast('Error', 'Failed to updated company name. Please try again or contact support.')
      }

      this.updatingCompanyName = false
    },
    async loadEntityTypeSuffixes() {
      const jurisdictionId = this.company.domestic_registration?.jurisdiction_id
      if (jurisdictionId) {
        await this.getEntityTypeSuffixes({ entityTypeId: this.entityTypeId, jurisdictionId: jurisdictionId })
      }
    },
    async performCompanyNameSearch(name = null) {
      const searchName = name || this.newCompanyName || this.companyName
      this.companyNameSearching = true

      try {

        if (!this.successfulNames.includes(searchName)) {
          const response = await this.searchCompanyName({
            companyName: searchName,
            entityTypeId: this.entityTypeId,
            jurisdiction: this.company.domestic_registration.jurisdiction.state_province_region,
            companyId: this.company.id,
          })

          this.companyNameAvailable = response.data.result.success || false

          logClientInteraction({
            clientInteractionLogId: this.verifyOrderInteraction.id,
            interaction: {
              category: 'company-name-search',
              jurisdiction: this.company.domestic_registration.jurisdiction.state_province_region,
              searchName: searchName,
              nameAvailable: this.companyNameAvailable,
            },
          })

          if (!this.companyNameAvailable && this.firstSearch) this.firstSearch = false
          if (this.companyNameAvailable) this.cacheName(
            this.newCompanyName ?
            `${this.newCompanyName} ${this.selectedEntitySuffix}` :
            searchName
          )
          this.unavailableMessage = response.data.result.message || 'Name unavailable.'
        } else {
          this.companyNameAvailable = true
        }
      } catch(err) {
        this.companyNameAvailable = true
        this.errorToast('Error', `Something went wrong: ${err}`)
      }

      if (name) {
        this.currentCompanyName = name
      } else {
        this.currentCompanyName = this.newCompanyName ?
          `${this.newCompanyName} ${this.selectedEntitySuffix}` :
          searchName
      }

      this.runNewSearch = false
      this.companyNameSearching = false
    },
    openSearchInput() {
      this.runNewSearch = true
    },
    cacheName(name) {
      if (this.successfulNames.length >= 3) this.successfulNames.shift()
      if (!this.successfulNames.includes(name)) this.successfulNames.push(name)
    },
  },
}
</script>

<style lang="scss" scoped>
// Animation
@keyframes blink {
  0% {
    opacity: 0.0;
  }
  20% {
    opacity: 1.0;
  }
  100% {
    opacity: 0.0;
  }
}

// Typography
// Typography Palette
$teal1: #2FB2AE;
$red1: #BC302E;
$light-grey: #495057;

// Typography: Font-Weights
$font-weight-400: $ct-ui-font-weight-4;
$font-weight-600: $ct-ui-font-weight-6;
$font-weight-700: $ct-ui-font-weight-7;

// Typography: Mixin
@mixin typography($font-size: 1.0em, $font-weight: $font-weight-600, $color: black) {
  font-family: "Open Sans", "Roboto", sans-serif;
  font-size: $font-size;
  font-weight: $font-weight;
  color: $color;
}

#verify-order-accordion-1 {

  p {
    margin: 0;
    text-align: left;
  }

  .searching-container {

    .searching-wait {
      @include typography(
        $font-weight: $font-weight-400
      );
      margin: 0;
    }

    .searching-availability {
      @include typography(
        $font-size: 1.5em,
        $font-weight: $font-weight-700
      );
      margin: 0;

      .searching-dot {
        padding: 0 0.1em;
        animation-name: blink;
        animation-duration: 3.0s;
        animation-iteration-count: infinite;
        animation-fill-mode: both;
      }

      .searching-dot:nth-child(2) {
        animation-delay: 0.25s;
      }

      .searching-dot:nth-child(3) {
        animation-delay: 0.75s;
      }
    }
  }

  .availability-container {
    padding: 1.0em 0;

    .grid-container {
      display: grid;
      grid-template-columns: auto auto;
      column-gap: 0.5em;

      .grid-column-1 {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding-top: 0.5em;
        max-width: 4.625em;

        .icon-container {
          padding-top: 1em;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          width: 100%;
          margin-right: 1.5em;

          .available-icon-area {
            padding-bottom: 2.875em;

            .check-circle {
              path { fill: $teal1 !important }
            }

            .success-check-circle {
              width: 74px;
              height: 74px;
            }
          }
        }
      }

      .grid-column-2 {
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-start;

        .available {
          max-width: 40.625em !important;
          width: 100%;
          height: 100%;
          padding: 1.0em 0;
          display: flex;
          flex-flow: column;
          justify-content: center;
          align-items: flex-start;
          column-gap: 1.0em;

          .available-confirm {
            @include typography(
              $color: $teal1
            );
          }

          .company-name {
            @include typography(
              $font-size: 1.5em,
              $font-weight: $font-weight-700
            );
          }
        }

        .not-available-confirm {
          @include typography(
            $font-size: 1.5em,
            $color: $red1
          );

          .company-name {
            @include typography(
              $font-weight: $font-weight-700
            );
          }
        }

        .input-header {
          @include typography();
          margin-top: 1.5em;
          margin-bottom: 0.5em;
        }

        .input-container {
          max-width: 40em;
          display: flex;
          flex-flow: row;
          justify-content: center;
          align-items: flex-start;
          column-gap: 0.5em;

          input {
            @include typography(
              $font-weight: $font-weight-400,
              $color: $light-grey
            );
            padding: 0.3125em 0.5em !important;
            border: 1px solid #CED4dA;
            border-radius: 4px;
          }

          select {
            @include typography(
              $font-weight: $font-weight-400,
              $color: $light-grey
            );
            height: 2.25em !important;
            min-height: 2.25em !important;
          }
        }
      }
    }
  }

  .successful-matches {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: center;
    align-items: flex-start;
    max-width: 80%;
    margin: 0 auto 1em;

    .successful-matches-text {
      margin-bottom: 1em;
    }

    .successful-matches-buttons {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      max-width: 25em;

      .successful-match-button {
        display: inline-flex;
        align-items: center;
        width: fit-content;
        margin-left: 1em;
        margin-bottom: 1em;
      }
    }
  }

  .availability-note {
    @include typography(
      $font-size: 0.75em,
      $font-weight: $font-weight-400
    );

    display: block;
  }
}

@media only screen and (max-width: 768px) {
  #verify-order-accordion-1 {

    .availability-container {

      .icon-container {

        .available-icon-area {
          padding-bottom: 1.375em;
        }
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  #verify-order-accordion-1 {

    .searching-container {

      .searching-wait {
        @include typography(
          $font-size: 0.875em,
          $font-weight: $font-weight-400
        );
        margin: 0;
      }

      .searching-availability {
        @include typography(
          $font-size: 1.25em,
          $font-weight: $font-weight-700
        );
      }
    }

    .availability-container {
      padding: 0;
      flex-flow: column;
      align-items: center;

      .icon-container {
        margin-left: 0;
        display: flex;
        justify-content: flex-start;
        width: 100%;

        .available-icon-area {
          padding-bottom: 1em;
        }
      }

      .state-confirmation {
        @include typography(
          $font-size: 0.875em,
        );
        padding-bottom: 0.5em;
      }

      .grid-column-2 {
        .input-container {
          flex-flow: column !important;
          max-width: 100%;
          justify-content: center;
          align-items: flex-start;
          column-gap: 0;
          row-gap: 0.5em;

          input {
            @include typography(
              $font-weight: $font-weight-400,
              $color: #495057
            );
            width: 100%;
            padding: 0.3125em 1.0em;
            border: 1px solid #CED4dA;
            border-radius: 4px;
          }

          button {
            width: 100%;
          }
        }
      }
    }

    .successful-matches {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .successful-matches-text {
        text-align: center;
      }

      .successful-matches-buttons {
        display: flex;
        flex-direction: column;

        .successful-match-button {
          margin-left: 0;
        }
      }
    }
  }
}
</style>
